import {
	Component,
	Input,
	OnInit,
	ViewChild,
	ChangeDetectorRef,
	ComponentFactoryResolver,
	ViewContainerRef,
	OnDestroy
} from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { IframeComponent } from "../iframe/iframe.component";
import { AppComponent } from "../app.component";
import { GetDashboardService } from "../services/get-dashboard.service";
import { Subject, Subscription } from "rxjs";
import { MatInput } from "@angular/material/input";
import { MatDatepickerInput, MatStartDate } from "@angular/material/datepicker";
import { environment } from "../../environments/environment";
import { FormControl } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";

@Component({
	selector: "app-dashboard-query",
	templateUrl: "./dashboard-query.component.html",
	styleUrls: ["./dashboard-query.component.css"]
})
export class DashboardQueryComponent implements OnInit, OnDestroy {
	dashboardsCollection: any;
	navId: "dashboard";
	subscription: Subscription;
	defaultMenuId: any;

	initial: any;
	selectedItem: any;
	endDate: any;
	number: any;
	defaultStartDate: any;
	defaultEndDate: any;
	defaultStartDateString: any;
	defaultEndDateString: any;
	constructor(
		private dashboardService: GetDashboardService,
		private route: ActivatedRoute,
		private sanitizer: DomSanitizer,
		private appcomp: AppComponent,
		private componentFactoryResolver: ComponentFactoryResolver,
		public container: ViewContainerRef,
		public changeDetector: ChangeDetectorRef
	) {}

	ngOnInit(): void {
		this.defaultStartDateString = this.appcomp.defaultStartDateString;
		this.defaultEndDateString = this.appcomp.defaultEndDateString;
		//Query startdate and end date are assigned respectively
		if (this.appcomp.startdateQuery != "" && this.appcomp.enddateQuery != "") {
			this.defaultStartDate = new FormControl(new Date(new Date(this.appcomp.startdateQuery).getTime()));
			this.defaultEndDate = new FormControl(new Date(new Date(this.appcomp.enddateQuery).getTime()));
		}
		//If query startdate and end date is not passed default start date and end date are assigned
		else {
			this.defaultStartDate = new FormControl(new Date(new Date().getTime() - 30 * 24 * 60 * 60 * 1000));
			this.defaultEndDate = new FormControl(new Date(new Date().getTime()));
		}
		this.initial = 0;
		this.dashboardService.dashboardsChanged.subscribe((dashboardsCollections) => {
			this.dashboardsCollection = dashboardsCollections;
			this.initialization();
			//localeCompare and sort are used to sort the names of dahboards in the clsidenav
			//localecompare is used since we are dealing with multi language dashboards
			this.dashboardsCollection = this.dashboardsCollection.sort((a: any, b: any): number => {
				return a.name.localeCompare(b.name, this.appcomp.currentlanguage);
			});
			//defaultmenuid is the dashboard menu item that is displayed when SPA is loaded initially
			this.defaultMenuId = this.dashboardsCollection[0].id;
		});
		this.initialization();
		this.dashboardsCollection = this.dashboardsCollection.sort((a: any, b: any): number => {
			return a.name.localeCompare(b.name, navigator.language);
		});

		this.defaultMenuId = this.dashboardsCollection[0].id;
	}

	initialization() {
		this.dashboardsCollection = this.appcomp.dashboardsCollection;
		let i;
		setTimeout(() => {
			for (i = 0; i < this.dashboardsCollection.length; i++) {
				this.dashboardsCollection[i].navigation = this.dashboardsCollection[i]["href"];
			}
		}, 100);
	}

	//When a menuitem in cl-sidenav is clicked load function is called
	//value is href of the menuitem
	load(value) {
		console.log(this.dashboardsCollection);
		this.selectedItem = value;
		let j;
		//index value of the selected menu item is fetched
		for (j = 0; j < this.dashboardsCollection.length; j++) {
			if (this.dashboardsCollection[j]["href"] == this.selectedItem) {
				this.number = j;
			}
		}
		this.initial = 1;
		//dynamic component is created and view is loaded in iframe
		const componentFactory = this.componentFactoryResolver.resolveComponentFactory(IframeComponent);
		this.container.clear();
		const componentRef = this.container.createComponent(componentFactory);
		value = this.sanitizer.bypassSecurityTrustResourceUrl(value);
		componentRef.instance.data = value;
		componentRef.instance.changeDetector.markForCheck();
	}

	//Whenever the startdate is changed in the datepicker url gets changed and api call is made
	startDateChanged($event) {
		environment.urlDashboard = environment.urlDashboardConstant + "&start_date=" + this.convertDatetoString($event.target.value);
		environment.urlDashboardsidenav = environment.urlDashboardConstant + "&start_date=" + this.convertDatetoString($event.target.value);
	}

	//Whenver the enddate is changed in the datepicker endDateChnaged gets invoked and api call is made with modified URL
	endDateChanged($event) {
		this.endDate = this.convertDatetoString($event.target.value);
		//When only startdate is selected, enddate defaults to 1970-01-01 by datepicker
		//This 1970-01-01 gets changed to current date
		if (this.endDate == "1970-01-01") {
			this.endDate = this.defaultEndDateString;
		}

		let len = environment.urlDashboard.length;
		if (environment.urlDashboard.substring(len - 18, len - 10) == "end_date=") {
			environment.urlDashboard = environment.urlDashboard.substring(0, len - 19);
		}
		environment.urlDashboard = environment.urlDashboard + "&end_date=" + this.endDate;
		environment.urlDashboardsidenav = environment.urlDashboardsidenav + "&end_date=" + this.endDate;

		this.appcomp.doApiCall();
		this.dashboardService.dashboardsChanged.subscribe((dashboardsCollection) => {
			this.load(this.dashboardsCollection[this.number].href);
		});
	}

	convertDatetoString(str) {
		var date = new Date(str),
			mnth = ("0" + (date.getMonth() + 1)).slice(-2),
			day = ("0" + date.getDate()).slice(-2);
		return [date.getFullYear(), mnth, day].join("-");
	}

	ngOnDestroy() {
		this.dashboardService.dashboardsChanged.unsubscribe();
	}
}
